<template>
  <template v-for="(item, index) of collectionSlice" :key="index">
    <slot :item="item" :index="index" />
  </template>
  <slot v-if="collectionLength === 0" name="if-empty" />
  <component :is="props.loaderContainer" ref="loaderContainerElement" />
</template>
<script setup lang="ts" generic="T">
import { useVisibility } from '@component-utils/visibility';
import { computed, ref, watch, type Component } from 'vue';

defineOptions({
  name: 'VLazyDisplayable'
})

const props = withDefaults(
  defineProps<{
    collection: T[]
    size: number
    start?: number
    loaderContainer?: keyof HTMLElementTagNameMap | Component
  }>(),
  {
    loaderContainer: 'div',
    start: undefined
  }
)

const slots = defineSlots<{
  default(props: { item: T, index: number }): any
  ['if-empty'](): any
}>()

const collection = computed(() => props.collection)
const collectionSlice = computed(() => collection.value.slice(0, count.value))
const collectionLength = computed(() => collection.value.length)

const count = ref(props.start ?? props.size)

const loaderContainerElement = ref<HTMLElement>()

const isVisible = useVisibility(loaderContainerElement)
watch(isVisible, (visible) => {
  if (visible && count.value < collectionLength.value) count.value += props.size
})
</script>